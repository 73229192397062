import PropTypes from "prop-types";
import React, { useState } from "react";

import Loadable from "@loadable/component";
import { Box, Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ButtonVK from "./ButtonVK";

HelpVideo.propTypes = {
  videoText: PropTypes.node,
  videoUrl: PropTypes.string,
};

export default function HelpVideo({ videoText, videoUrl }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Dialog
        PaperProps={{ style: { overflow: "hidden", background: "rgba(0,0,0, 0.4)" } }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <Box color="white" display="flex" justifyContent="flex-end">
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
        <iframe src="https://vk.com/video_ext.php?oid=-119467643&id=456239056&hash=7eb9c86755528cf5" width="600" height="340" frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </Dialog>
      <ButtonVK label={videoText} onClick={handleOpen} />
    </>
  );
}
